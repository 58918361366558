import React, { useState } from 'react';

import {useTranslation} from "react-i18next";

import classnames from 'classnames'

import BarLoader from "react-spinners/BarLoader";

import emailjs from "@emailjs/browser";

import Swal from 'sweetalert2'

import { ScrollMenu, 
// VisibilityContext
} from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';

import './App.css';
// eslint-disable-next-line
import { FiMenu } from 'react-icons/fi'
import { 
  // IoCartOutline, IoChatbubbleOutline, 
  IoClose,
  //  IoDesktopOutline, 
   IoLogoCss3, IoLogoHtml5, IoLogoJavascript, IoLogoNodejs, IoLogoPython, IoLogoWordpress,
    // IoPaperPlaneOutline, IoPhonePortraitOutline 
  } from 'react-icons/io5';
import { BsArrowDownCircle, BsGithub, BsLinkedin } from 'react-icons/bs';
import { BiLogoFlutter } from 'react-icons/bi'
// import { GiSpiderWeb } from 'react-icons/gi';
import { FaJava, FaReact, FaShopify } from 'react-icons/fa';
import { DiMongodb, DiRubyRough, DiSwift } from 'react-icons/di';
import { SiDart, SiGooglecloud, SiMicrosoftazure, SiRubyonrails } from 'react-icons/si';
// import {LiaPenSolid} from 'react-icons/lia'

import { Link } from 'react-scroll'

function App() {
  (function(){
    emailjs.init("h8kxj57808xXBZatc");
  })();

  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line
  const {t, i18n} = useTranslation('common');
  
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  // eslint-disable-next-line
  const toggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  }

  const [contactFormName, setContactFormName] = useState();
  const [contactFormEmail, setContactFormEmail] = useState();
  const [contactFormSubject, setContactFormSubject] = useState();
  const [contactFormMessage, setContactFormMessage] = useState();

  var templateParams = {
    from_name: contactFormName,
    from_email: contactFormEmail,
    subject: contactFormSubject,
    message: contactFormMessage
  };

  const sendContactForm = () => {
    if (contactFormName && contactFormEmail && contactFormSubject && contactFormMessage){
    setIsLoading(true);
    emailjs.send('sprangerventures_service', 'contact_form', templateParams)
        .then(function(response) {
          console.log('Success!', response.status, response.text);
          setIsLoading(false);
          Swal.fire({
            text: t('contactFormSuccessMessage'),
            icon: 'success',
            confirmButtonColor: '#FFFFFF',
            confirmButtonText: t('done'),
          })
        }, function(error) {
          console.log('Failed...', error);
          setIsLoading(false);
      });

    setContactFormName('');
    setContactFormEmail('');
    setContactFormSubject('');
    setContactFormMessage('');
    } else {
      Swal.fire({
        text: t('contactFormFailureMessage'),
        icon: 'error',
        confirmButtonColor: '#FFFFFF',
        confirmButtonText: t('okay'),
      })
    }
  }

  const [activeLanguage, setActiveLanguage] = useState('en')

  const toggleLanguage = (language) => {
    i18n.changeLanguage(language);
    setActiveLanguage(language)
  }

  if (isLoading) return (
    <div className='loadingPage'>
      <BarLoader color='#FFFFFF' />
    </div>
  )

  return (
    <div className="App">
      {
        sidebarIsOpen ?
          <div id='sidebar'>
            <div id='sidebarContainer'>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'end'}}>
                <IoClose onClick={toggleSidebar}/>
              </div>
              <Link className='navLink' to="whatIDoSection" spy={true} smooth={true} offset={0} duration={500}>
                {t('whatIDo')}
              </Link>
              <Link className='navLink' to="howIDoItSection" spy={true} smooth={true} offset={0} duration={500}>
                {t('howIDoIt')}
              </Link>
              <Link className='navLink' to="myExperienceSection" spy={true} smooth={true} offset={0} duration={500}>
                {t('myExperience')}
              </Link>
              <Link className='navLink' to="projectsSection" spy={true} smooth={true} offset={0} duration={500}>
                {t('projects')}
              </Link>
              <Link className='navLink' to="getInTouchSection" spy={true} smooth={true} offset={0} duration={500}>
                {t('getInTouch')}
              </Link>
              <div className='navLanguageToggle'>
                <p onClick={() => toggleLanguage('en')} style={{paddingRight: '0.5rem', borderRight: '1px solid white'}} className={classnames(
                  {
                   	active: activeLanguage === 'en'
                  }
                )}>
                  EN
                </p>
                <p onClick={() => toggleLanguage('es')} style={{paddingLeft: '0.5rem', paddingRight: '0.5rem', borderRight: '1px solid white'}} className={classnames(
                  {
                   	active: activeLanguage === 'es'
                  }
                )}>
                  ES
                </p>
                <p onClick={() => toggleLanguage('de')} style={{paddingLeft: '0.5rem'}} className={classnames(
                  {
                   	active: activeLanguage === 'de'
                  }
                )}>
                  DE
                </p>
              </div>
            </div>
          </div>
        : null
      }
      <section id='heroSection'>
        <div id='myNavbar'>
          <div id='myNavbarContainer' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '1rem 5rem'}}>
            <p id='navbarLogo'>
              Spranger Ventures
            </p>
            <div id='desktopNavbarItems' style={{display: 'flex', flexDirection: 'row'}}>
              <Link className='navLink' to="whatIDoSection" spy={true} smooth={true} offset={0} duration={500}>
                {t('whatIDo')}
              </Link>
              <Link className='navLink' to="howIDoItSection" spy={true} smooth={true} offset={0} duration={500}>
                {t('howIDoIt')}
              </Link>
              <Link className='navLink' to="myExperienceSection" spy={true} smooth={true} offset={0} duration={500}>
                {t('myExperience')}
              </Link>
              <Link className='navLink' to="projectsSection" spy={true} smooth={true} offset={0} duration={500}>
                {t('projects')}
              </Link>
              <Link className='navLink' to="getInTouchSection" spy={true} smooth={true} offset={0} duration={500}>
                {t('getInTouch')}
              </Link>
              <div className='navLanguageToggle'>
                <p onClick={() => toggleLanguage('en')} style={{paddingRight: '0.5rem', borderRight: '1px solid white'}} className={classnames(
                  {
                   	active: activeLanguage === 'en'
                  }
                )}>
                  EN
                </p>
                <p onClick={() => toggleLanguage('es')} style={{paddingLeft: '0.5rem', paddingRight: '0.5rem', borderRight: '1px solid white'}} className={classnames(
                  {
                   	active: activeLanguage === 'es'
                  }
                )}>
                  ES
                </p>
                <p onClick={() => toggleLanguage('de')} style={{paddingLeft: '0.5rem'}} className={classnames(
                  {
                   	active: activeLanguage === 'de'
                  }
                )}>
                  DE
                </p>
              </div>
            </div>
            <FiMenu id='menuToggler' onClick={toggleSidebar} />
          </div>
        </div>
        <div id='heroContainer'>
          <h1 id='heroHeader'>
            {t('hi')}<br className='hideOnDesktop'/>{t('imDiego')}
          </h1>
          <p id='heroSubheader'>
            {t('currentJobTitle')}
          </p>
        </div>
        <Link to="whatIDoSection" spy={true} smooth={true} offset={0} duration={500}>
          <BsArrowDownCircle id='heroSectionArrowDownIcon' />
        </Link>
      </section>
      <section id='whatIDoSection' className='section'>
        <h2 className='sectionHeader'>
          {t('whatIDo')}
        </h2>
        <p className='sectionSubheader'>
          {t('whatIDoSubheader')}
        </p>
        <ScrollMenu>
        <div className='iconCard'>
          <p>
            Cloud infrastructure
          </p>
        </div>
        {/* <div className='iconCard'>
          <IoDesktopOutline />
          <p>
            Web development
          </p>
        </div> */}
        <div className='iconCard'>
          {/* <IoPhonePortraitOutline /> */}
          <p>
            {/* {t('mobileApps')} */}
            Software development
          </p>
        </div>
        <div className='iconCard'>
          <p>
            Data security
          </p>
        </div>
        <div className='iconCard'>
          <p>
            DevOps
          </p>
        </div>
        <div className='iconCard'>
          <p>
            API management
          </p>
        </div>
        <div className='iconCard'>
          <p>
            Data analytics
          </p>
        </div>
        <div className='iconCard'>
          <p>
            Machine learning
          </p>
        </div>
        {/* <div className='iconCard'>
          <IoPaperPlaneOutline />
          <p>
            Landing pages
          </p>
        </div> */}
        <div className='iconCard'>
          {/* <IoCartOutline /> */}
          <p>
            E-commerce
          </p>
        </div>
        {/* <div className='iconCard'>
          <IoChatbubbleOutline />
          <p>
            Blogs
          </p>
        </div>
        <div className='iconCard'>
          <GiSpiderWeb />
          <p>
            Scrapers
          </p>
        </div> */}
        <div className='iconCard'>
          {/* <LiaPenSolid /> */}
          <p>
            {t('uxUiDesign')}
          </p>
        </div>
        </ScrollMenu>
      </section>
      <section id='howIDoItSection' className='section'>
        <h2 className='sectionHeader'>
          {t('howIDoIt')}
        </h2>
        <p className='sectionSubheader'>
          {t('howIDoItSubheader')}
        </p>
        <ScrollMenu>
          <div className='iconCard'>
            <SiMicrosoftazure />
            <p>Azure</p>
          </div>
          <div className='iconCard'>
            <SiGooglecloud />
            <p>GCP</p>
          </div>
          <div className='iconCard'>
            <FaReact />
            <p>React</p>
          </div>
          <div className='iconCard'>
            <IoLogoJavascript />
            <p>Javascript</p>
          </div>
          <div className='iconCard'>
            <IoLogoNodejs />
            <p>Node.js</p>
          </div>
          <div className='iconCard'>
            <DiMongodb />
            <p>MongoDB</p>
          </div>
          <div className='iconCard'>
            <IoLogoPython />
            <p>Python</p>
          </div>
          <div className='iconCard'>
            <DiRubyRough />
            <p>Ruby</p>
          </div>
          <div className='iconCard'>
            <SiRubyonrails />
            <p>Ruby on Rails</p>
          </div>
          <div className='iconCard'>
            <FaJava />
            <p>Java</p>
          </div>
          <div className='iconCard'>
            <FaReact />
            <p>React Native</p>
          </div>
          <div className='iconCard'>
            <DiSwift />
            <p>Swift</p>
          </div>
          <div className='iconCard'>
            <SiDart />
            <p>Dart</p>
          </div>
          <div className='iconCard'>
            <BiLogoFlutter />
            <p>Flutter</p>
          </div>
          <div className='iconCard'>
            <IoLogoHtml5 />
            <p>HTML</p>
          </div>
          <div className='iconCard'>
            <IoLogoCss3 />
            <p>CSS</p>
          </div>
          <div className='iconCard'>
            <FaShopify />
            <p>Shopify</p>
          </div>
          <div className='iconCard'>
            <IoLogoWordpress />
            <p>Wordpress</p>
          </div>
        </ScrollMenu>
      </section>
      <section id='myExperienceSection' className='section'>
        <h2 className='sectionHeader'>
          {t('myExperience')}
        </h2>
        <p className='sectionSubheader'>
          {t('myExperienceSubheader')}
        </p>
        <div className='myExperienceCard'>
          <div className='myExperienceImageContainer'>
            <img src='https://firebasestorage.googleapis.com/v0/b/pgs360-firebase.appspot.com/o/pgs360Thumbnail.png?alt=media&token=13ff5a32-818c-4e10-91cf-5df642a80f96' alt='experienceLogo' />
          </div>
          <div className='myExperienceTextContainer'>
            <p className='experienceRole'>
              Director of IT
            </p>
            <p className='experienceCompany'>
              PGS360
            </p>
            <p className='experienceDates'>
              Feb 2024 - {t('present')}
            </p>
            <p className='experienceLocation'>
              California, USA
            </p>
          </div>
        </div>
        <div className='hr'></div>
        <div className='myExperienceCard'>
          <div className='myExperienceImageContainer'>
            <img src='https://res.cloudinary.com/da3qtg7t5/image/upload/v1697813274/Spranger%20Ventures/Vector_zgvozt.png' alt='experienceLogo' />
          </div>
          <div className='myExperienceTextContainer'>
            <p className='experienceRole'>
              Tech lead
            </p>
            <p className='experienceCompany'>
              Everfin
            </p>
            <p className='experienceDates'>
              Sep 2022 - {t('present')}
            </p>
            <p className='experienceLocation'>
              Zurich, Switzerland
            </p>
          </div>
        </div>
        <div className='hr'></div>
        <div className='myExperienceCard'>
          <div className='myExperienceImageContainer'>
            <img src='https://res.cloudinary.com/da3qtg7t5/image/upload/v1697813274/Spranger%20Ventures/Instagram_post_Hello_Farmer_v1_lcb98a.png' alt='experienceLogo' />
          </div>
          <div className='myExperienceTextContainer'>
            <p className='experienceRole'>
              Co-Founder
            </p>
            <p className='experienceCompany'>
              HelloFarmer
            </p>
            <p className='experienceDates'>
              Sep 2021 - {t('present')}
            </p>
            <p className='experienceLocation'>
              Berlin, Germany
            </p>
          </div>
        </div>
        <div className='hr'></div>
        <div className='myExperienceCard'>
          <div className='myExperienceImageContainer'>
            <img src='https://res.cloudinary.com/da3qtg7t5/image/upload/v1697813274/Spranger%20Ventures/Group_12_imbjge.png' alt='experienceLogo' />
          </div>
          <div className='myExperienceTextContainer'>
            <p className='experienceRole'>
              Co-Founder
            </p>
            <p className='experienceCompany'>
              Gazindo
            </p>
            <p className='experienceDates'>
              Sep 2020 - {t('present')}
            </p>
            <p className='experienceLocation'>
              Berlin, Germany
            </p>
          </div>
        </div>
        <div className='hr'></div>
        <div className='myExperienceCard'>
          <div className='myExperienceImageContainer'>
            <img src='https://firebasestorage.googleapis.com/v0/b/bautory-app.appspot.com/o/assets%2Fbranding%2FbautoryThumbnail.png?alt=media&token=e9bcfcfe-e894-4fef-828f-eb121eee9320' alt='experienceLogo' />
          </div>
          <div className='myExperienceTextContainer'>
            <p className='experienceRole'>
              Co-Founder
            </p>
            <p className='experienceCompany'>
              Bautory
            </p>
            <p className='experienceDates'>
              Jun 2020 - {t('present')}
            </p>
            <p className='experienceLocation'>
              Berlin, Germany
            </p>
          </div>
        </div>
        <div className='hr'></div>
        <div className='myExperienceCard'>
          <div className='myExperienceImageContainer'>
            <img src='https://res.cloudinary.com/da3qtg7t5/image/upload/v1697813274/Spranger%20Ventures/image_14_snakpq.png' alt='experienceLogo' />
          </div>
          <div className='myExperienceTextContainer'>
            <p className='experienceRole'>
              Software Engineer
            </p>
            <p className='experienceCompany'>
              Chatterbug
            </p>
            <p className='experienceDates'>
              Oct 2019 - {t('aug')} 2020
            </p>
            <p className='experienceLocation'>
              Berlin, Germany
            </p>
          </div>
        </div>
        <div className='hr'></div>
        <div className='myExperienceCard'>
          <div className='myExperienceImageContainer'>
            <img src='https://res.cloudinary.com/da3qtg7t5/image/upload/v1697813274/Spranger%20Ventures/Group_26_pufnjn.png' alt='experienceLogo' />
          </div>
          <div className='myExperienceTextContainer'>
            <p className='experienceRole'>
              Software Engineer
            </p>
            <p className='experienceCompany'>
              Cirplus
            </p>
            <p className='experienceDates'>
              Mar 2019 - {t('apr')} 2020
            </p>
            <p className='experienceLocation'>
              Berlin, Germany
            </p>
          </div>
        </div>
      </section>
      <section id='projectsSection' className='section'>
        <h2 className='sectionHeader'>
          {t('projects')}
        </h2>
        <p className='sectionSubheader'>
          {t('projectsSubheader')}
        </p>
        <ScrollMenu>
        <a href='https://internationaladvisors-landing.netlify.app/' target='_blank' rel='noreferrer'>
          <div className='projectsCard'>
            <img src='https://firebasestorage.googleapis.com/v0/b/spranger-ventures.appspot.com/o/Portfolio%2Finternational-advisors.png?alt=media&token=9752446f-1de8-4c13-ad94-c476d090eaba' alt='experienceLogo' />
            <div className='projectsCardTextContainer'>
              <p className='projectsCardTitle'>
                International Advisors
              </p>
              <p className='projectsCardDescription'>
                {t('internationalAdvisorsDescription')}
              </p>
            </div>
          </div>
        </a>
        <a href='https://sportleague.net/' target='_blank' rel='noreferrer'>
          <div className='projectsCard'>
            <img src='https://firebasestorage.googleapis.com/v0/b/spranger-ventures.appspot.com/o/Portfolio%2Fsportleague.png?alt=media&token=49c19711-de02-46c1-891b-eb32967c11fa' alt='experienceLogo' />
            <div className='projectsCardTextContainer'>
              <p className='projectsCardTitle'>
                Sportleague
              </p>
              <p className='projectsCardDescription'>
                {t('sportleagueDescription')}
              </p>
            </div>
          </div>
        </a>
        <a href='https://www.everfin.ch/' target='_blank' rel='noreferrer'>
          <div className='projectsCard'>
            <img src='https://res.cloudinary.com/da3qtg7t5/image/upload/v1697817208/Spranger%20Ventures/Projects/Screenshot_2023-10-20_at_09.21_1_qlnnwn.png' alt='experienceLogo' />
            <div className='projectsCardTextContainer'>
              <p className='projectsCardTitle'>
                Everfin
              </p>
              <p className='projectsCardDescription'>
                {t('everfinDescription')}
              </p>
            </div>
          </div>
          </a>
          <a href='https://letsmovedancestudio.com/' target='_blank' rel='noreferrer'>
          <div className='projectsCard'>
            <img src='https://res.cloudinary.com/da3qtg7t5/image/upload/v1697817213/Spranger%20Ventures/Projects/Screenshot_2023-10-20_at_09.11_1_mchk55.png' alt='experienceLogo' />
            <div className='projectsCardTextContainer'>
              <p className='projectsCardTitle'>
                Let's Move Dance Studio
              </p>
              <p className='projectsCardDescription'>
                {t('lmdsDescription')}
              </p>
            </div>
          </div>
          </a>
          <a href='https://www.gazindo.de/' target='_blank' rel='noreferrer'>
          <div className='projectsCard'>
            <img src='https://res.cloudinary.com/da3qtg7t5/image/upload/v1697817211/Spranger%20Ventures/Projects/Screenshot_2023-10-20_at_09.20_2_hhuhsx.png' alt='experienceLogo' />
            <div className='projectsCardTextContainer'>
              <p className='projectsCardTitle'>
                Gazindo
              </p>
              <p className='projectsCardDescription'>
                {t('gazindoDescription')}
              </p>
            </div>
          </div>
          </a>
          <a href='https://www.recienhorneadobakery.com/' target='_blank' rel='noreferrer'>
          <div className='projectsCard'>
            <img src='https://res.cloudinary.com/da3qtg7t5/image/upload/v1697817208/Spranger%20Ventures/Projects/Screenshot_2023-10-20_at_09.19_1_uurhrv.png' alt='experienceLogo' />
            <div className='projectsCardTextContainer'>
              <p className='projectsCardTitle'>
                Recien Horneado Bakery
              </p>
              <p className='projectsCardDescription'>
                {t('rhDescription')}
              </p>
            </div>
          </div>
          </a>
          <a href='https://www.hellofarmer.de/' target='_blank' rel='noreferrer'>
          <div className='projectsCard'>
            <img src='https://res.cloudinary.com/da3qtg7t5/image/upload/v1697817210/Spranger%20Ventures/Projects/Screenshot_2023-10-20_at_09.20_1_xpgrm1.png' alt='experienceLogo' />
            <div className='projectsCardTextContainer'>
              <p className='projectsCardTitle'>
                HelloFarmer
              </p>
              <p className='projectsCardDescription'>
                {t('hellofarmerDescription')}
              </p>
            </div>
          </div>
          </a>
          <a href='https://www.bautory.com/' target='_blank' rel='noreferrer'>
          <div className='projectsCard'>
            <img src='https://firebasestorage.googleapis.com/v0/b/bautory-app.appspot.com/o/assets%2Fbranding%2FbautoryForPortfolio.png?alt=media&token=9858ef87-2e36-4936-9f0f-dcb62b18b44b' alt='experienceLogo' />
            <div className='projectsCardTextContainer'>
              <p className='projectsCardTitle'>
                Bautory
              </p>
              <p className='projectsCardDescription'>
                {t('bautoryDescription')}
              </p>
            </div>
          </div>
          </a>        
          <a href='https://ggielsalvador.myshopify.com/' target='_blank' rel='noreferrer'>
          <div className='projectsCard'>
            <img src='https://res.cloudinary.com/da3qtg7t5/image/upload/v1697817215/Spranger%20Ventures/Projects/Screenshot_2023-10-20_at_09.16_1_fyftwo.png' alt='experienceLogo' />
            <div className='projectsCardTextContainer'>
              <p className='projectsCardTitle'>
                Garage Group International
              </p>
              <p className='projectsCardDescription'>
                {t('ggiDescription')}
              </p>
            </div>
          </div>
          </a>
          <a href='https://gazindolite.com/' target='_blank' rel='noreferrer'>
          <div className='projectsCard'>
            <img src='https://res.cloudinary.com/da3qtg7t5/image/upload/v1697817206/Spranger%20Ventures/Projects/Screenshot_2023-10-20_at_09.30_1_fz4txg.png' alt='experienceLogo' />
            <div className='projectsCardTextContainer'>
              <p className='projectsCardTitle'>
                Gazindo Lite
              </p>
              <p className='projectsCardDescription'>
                {t('gazindoLiteDescription')}
              </p>
            </div>
          </div>
          </a>
          <a href='https://www.passeioapp.com/' target='_blank' rel='noreferrer'>
          <div className='projectsCard'>
            <img src='https://res.cloudinary.com/da3qtg7t5/image/upload/v1697817209/Spranger%20Ventures/Projects/Screenshot_2023-10-20_at_09.25_1_j16kos.png' alt='experienceLogo' />
            <div className='projectsCardTextContainer'>
              <p className='projectsCardTitle'>
                Passeio Wishlists
              </p>
              <p className='projectsCardDescription'>
                {t('passeioDescription')}
              </p>
            </div>
          </div>
          </a>
          <a href='https://grupomaritimo.com/wp/' target='_blank' rel='noreferrer'>
          <div className='projectsCard'>
            <img src='https://res.cloudinary.com/da3qtg7t5/image/upload/v1697817206/Spranger%20Ventures/Projects/Group_85_rilyzh.png' alt='experienceLogo' />
            <div className='projectsCardTextContainer'>
              <p className='projectsCardTitle'>
                Grupo Maritimo
              </p>
              <p className='projectsCardDescription'>
                {t('gmDescription')}
              </p>
            </div>
          </div>
          </a>
          <a href='https://www.swiftapp.lat/' target='_blank' rel='noreferrer'>
          <div className='projectsCard'>
            <img src='https://res.cloudinary.com/da3qtg7t5/image/upload/v1697817206/Spranger%20Ventures/Projects/Group_84_ginn47.png' alt='experienceLogo' />
            <div className='projectsCardTextContainer'>
              <p className='projectsCardTitle'>
                Swift
              </p>
              <p className='projectsCardDescription'>
                {t('swiftDescription')}
              </p>
            </div>
          </div>
          </a>
          <a href='https://blog.chatterbug.com/en/' target='_blank' rel='noreferrer'>
          <div className='projectsCard'>
            <img src='https://res.cloudinary.com/da3qtg7t5/image/upload/v1697817210/Spranger%20Ventures/Projects/Screenshot_2023-10-20_at_09.08_1_bqvax8.png' alt='experienceLogo' />
            <div className='projectsCardTextContainer'>
              <p className='projectsCardTitle'>
                Chatterblog
              </p>
              <p className='projectsCardDescription'>
                {t('chatterblogDescription')}
              </p>
            </div>
          </div>
          </a>
        </ScrollMenu>
      </section>
      <section id='getInTouchSection' className='section'>
        <h2 className='sectionHeader'>
          {t('getInTouch')}
        </h2>
        <p className='sectionSubheader'>
          {t('getInTouchSubheader')}
        </p>
        <div>
          <div>
            <p className='inputLabel'>
              {t('name')}
            </p>
            <input value={contactFormName} onChange={(e) => setContactFormName(e.target.value)} type='text' placeholder={t('yourName')} />
          </div>
          <div>
            <p className='inputLabel'>
              {t('email')}
            </p>
            <input value={contactFormEmail} onChange={(e) => setContactFormEmail(e.target.value)} type='text' placeholder={t('yourEmail')} />
          </div>
          <div>
            <p className='inputLabel'>
              {t('subject')}
            </p>
            <input value={contactFormSubject} onChange={(e) => setContactFormSubject(e.target.value)} type='text' placeholder={t('theSubjectOfYourMessage')} />
          </div>
          <div style={{marginBottom: '0.5rem'}}>
            <p className='inputLabel'>
              {t('message')}
            </p>
            <textarea value={contactFormMessage} onChange={(e) => setContactFormMessage(e.target.value)} placeholder={t('yourMessage')} />
          </div>
          <button onClick={sendContactForm} className='primaryBtn'>
            {t('send')}
          </button>
        </div>
      </section>
      <footer>
        <p>
          ⓒ 2023 Spranger Ventures
        </p>
        <div id='footerSocialIconsContainer'>
          <a href='https://github.com/diego-s25' target='_blank' rel='noreferrer' className='footerSocialIconContainer'>
            <BsGithub />
          </a>
          <a href='https://www.linkedin.com/in/diego-spranger-297025154/' target='_blank' rel='noreferrer' className='footerSocialIconContainer'>
            <BsLinkedin />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
